import helpers from './helpers/helpers'

// Component structures
import seo from './components/seo'
import primaryHero from './components/primaryHero'
import ingredientsSpotlight from './components/ingredientsSpotlight'
import highlights from './components/highlights'
import multiButtonHero from '@/api/components/multiButtonHero'
import ingredientsList from '@/api/components/ingredientsList'
import ingredientSpecifications from '@/api/components/ingredientSpecifications'
import simpleContent from '@/api/components/simpleContent'
import videoHero from '@/api/components/videoHero'
import stepList from '@/api/components/stepList'
import topographyHero from '@/api/components/topographyHero'
import secondaryHero from '@/api/components/secondaryHero'

export default {
  loadHome() {
    const requestData = {
      query: `{
        entry(type: "home") {
          ... on home_home_Entry {
            ${seo}
            ${primaryHero}
            ${ingredientsSpotlight}
            ${highlights}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.home')
  },

  loadIngredients() {
    const requestData = {
      query: `{
        entry(slug: "ingredients") {
          ... on ingredients_ingredients_Entry {
            ${seo}
            ${multiButtonHero}
            ${ingredientsList}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.ingredients')
  },

  loadIngredient(ingredientSlug) {
    const requestData = {
      query: `{
        entry(uri: "ingredients/${ingredientSlug}") {
          ... on ingredient_default_Entry {
            ${seo}
            ${ingredientSpecifications}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(
      requestData,
      `view.ingredients.${ingredientSlug}`
    )
  },

  loadContact() {
    const requestData = {
      query: `{
        entry(slug: "contact") {
          ... on contact_contact_Entry {
            ${seo}
            ${simpleContent}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.contact')
  },

  loadProcess() {
    const requestData = {
      query: `{
        entry(slug: "process") {
          ... on process_process_Entry {
            ${seo}
            ${videoHero}
            ${stepList}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.process')
  },

  loadAbout() {
    const requestData = {
      query: `{
        entry(slug: "about") {
          ... on about_about_Entry {
            ${seo}
            ${topographyHero}
            ${highlights}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.about')
  },

  loadServices() {
    const requestData = {
      query: `{
        entry(slug: "services") {
          ... on services_services_Entry {
            ${seo}
            ${secondaryHero}
            ${highlights}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.services')
  },
}
