<template>
  <div class="center-image-highlight-container">
    <div
      class="container"
      v-observe-visibility="{
        callback: containerVisibilityChanged,
        once: true,
        throttle: 600,
        intersection: {
          threshold: 0.5,
        },
      }"
    >
      <div class="image-container">
        <img
          :src="highlightData.backgroundImage[0].url"
          :alt="highlightData.backgroundImage[0].title"
        />
        <div class="overlay"></div>
      </div>

      <div class="content-container">
        <h3>{{ highlightData.titleText }}</h3>
        <div class="text" v-html="highlightData.description"></div>
        <AnchorLink
          class="button-link"
          type="primaryButton"
          :anchor-link="highlightData.buttonLink"
        >
          {{ highlightData.buttonText }}
        </AnchorLink>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'CenterImageHighlight',
  components: { AnchorLink },
  props: {
    highlightData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    containerVisibilityChanged(isVisible, entry) {
      if (!isVisible) return
      entry.target.classList.add('showing')
    },
  },
}
</script>

<style lang="scss" scoped>
.center-image-highlight-container {
  .container {
    @apply relative my-12;

    &.showing {
      .image-container {
        .overlay {
          @apply opacity-100;
        }
      }

      .content-container {
        h3 {
          @apply opacity-100;
        }
        .text {
          @apply opacity-100;
        }
        .button-link {
          @apply opacity-100;
        }
      }
    }

    .image-container {
      @apply relative mx-0 overflow-hidden;

      img {
        @apply min-w-full max-w-none object-cover;
        margin-left: 50%;
        transform: translateX(-50%);
      }

      .overlay {
        @apply w-full h-full absolute top-0 opacity-0;
        background: rgba(32, 44, 57, 0.7);
        overflow: hidden;
        z-index: 1;
        transition: all 0.7s ease-in;
      }
    }

    .content-container {
      @apply absolute top-0 text-white w-full h-full flex flex-col items-center justify-center;
      z-index: 2;

      h3 {
        @apply uppercase whitespace-pre-wrap opacity-0;
        font-family: 'Inter Medium', sans-serif;
        font-size: 2em;
        transition: all 0.5s ease-in 0.7s;
      }

      .text {
        @apply mb-6 opacity-0;
        font-family: 'Inter Regular', sans-serif;
        font-size: 0.9em;
        line-height: 2em;
        letter-spacing: 1px;
        transition: all 0.5s ease-in 1s;
      }

      .button-link {
        @apply opacity-0;
        transition: all 0.5s ease-in 1.2s;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      .image-container {
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      @apply my-16;

      .image-container {
        @apply mx-12;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      @apply my-20;

      .image-container {
        @apply mx-20;
      }
    }
  }
}
</style>
