export default `ingredientsList {
  ... on ingredientsList_BlockType {
    description
    ingredients {
      ... on ingredient_default_Entry {
        id
        uri
        ingredientTile {
          ... on ingredientTile_BlockType {
            tallTileImage {
              url
              title
            }
            titleText
          }
        }
      }
    }
  }
}`
