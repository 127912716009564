export default `stepList {
  ... on stepList_step_BlockType {
    id
    titleText
    description
    stepImage {
      url
      title
    }
  }
}`
