<template>
  <div :class="[{ open: open }, 'mobile-navigation-container']">
    <nav id="mobileNav" aria-label="mobile navigation">
      <AnchorLink
        v-for="linkItem in headerData.linkList"
        class="nav-link"
        :key="linkItem.id"
        :anchor-link="linkItem.anchorLink"
        :new-tab="linkItem.newTab"
      >
        {{ linkItem.titleText }}
      </AnchorLink>

      <AnchorLink
        v-if="headerData.primaryButton"
        class="button-link"
        type="primaryButton"
        :anchor-link="headerData.primaryButton.buttonLink"
        :new-tab="headerData.primaryButton.newTab"
      >
        {{ headerData.primaryButton.buttonText }}
      </AnchorLink>
    </nav>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'MobileNavigation',
  components: { AnchorLink },
  props: {
    headerData: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-navigation-container {
  @apply bg-white py-0 overflow-hidden;
  max-height: 0 !important;
  transition: all 0.5s linear;

  &.open {
    @apply py-8;
    max-height: 1000px !important;
    border-bottom-width: 4px;
  }

  nav#mobileNav {
    @apply pb-6;

    a.nav-link {
      @apply block px-3 py-3 font-bold uppercase text-black overflow-hidden;
      font-family: 'Inter SemiBold', sans-serif;
      font-size: 1em;
      letter-spacing: 1px;
      transition: all 0.3s ease-in;

      &:hover,
      &:focus {
        background-color: rgba(25, 49, 83, 0.2);
      }
    }

    .button-link {
      @apply inline-block;
    }
  }

  @media screen and (min-width: 900px) {
    @apply hidden;
  }
}
</style>
