import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/ingredients',
    name: 'Ingredients',
    component: () =>
      import(/* webpackChunkName: "ingredients" */ '../views/Ingredients.vue'),
  },
  {
    path: '/ingredients/:ingredientSlug',
    name: 'Ingredient',
    component: () =>
      import(/* webpackChunkName: "ingredient" */ '../views/Ingredient.vue'),
  },
  {
    path: '/process',
    name: 'Process',
    component: () =>
      import(/* webpackChunkName: "process" */ '../views/Process.vue'),
  },
  {
    path: '/services',
    name: 'Services',
    component: () =>
      import(/* webpackChunkName: "services" */ '../views/Services.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/page-not-found',
    name: 'PageNotFound',
    component: () =>
      import(
        /* webpackChunkName: "pageNotFound" */ '../views/PageNotFound.vue'
      ),
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'PageNotFound' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }

    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.path !== from.path) {
    store.commit('setPageLoadingTrue')
  }

  next()
})

export default router
