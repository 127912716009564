export default `ingredientsSpotlight {
  ... on ingredientsSpotlight_BlockType {
    titleText
    description
    ingredients {
      ... on ingredient_default_Entry {
        id
        uri
        ingredientTile {
          ... on ingredientTile_BlockType {
            tileImage {
              url
              title
            }
            titleText
          }
        }
      }
    }
  }
}`
