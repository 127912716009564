<template>
  <div :class="[{ loaded: loaded }, 'primary-hero-container']">
    <div class="background-image">
      <img :src="primaryHeroData.backgroundImage[0].url" alt="" />
      <div class="background-gradient"></div>
    </div>

    <div class="container">
      <div class="content">
        <h2>{{ primaryHeroData.titleText }}</h2>
        <div class="text" v-html="primaryHeroData.description"></div>
        <AnchorLink
          v-if="primaryHeroData.buttonLink"
          class="button-link"
          type="primaryButton"
          :anchor-link="primaryHeroData.buttonLink"
        >
          {{ primaryHeroData.buttonText || 'Learn More' }}
        </AnchorLink>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'PrimaryHero',
  components: { AnchorLink },
  props: {
    primaryHeroData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
    }
  },
  mounted() {
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      const image = document.createElement('img')

      image.onload = () => {
        this.$emit('loaded')
        this.loaded = true
      }

      image.src = this.primaryHeroData.backgroundImage[0].url
    },
  },
}
</script>

<style lang="scss" scoped>
.primary-hero-container {
  @apply relative flex items-center justify-center;

  &.loaded {
    .background-image {
      .background-gradient {
        @apply opacity-100;
      }
    }

    .container {
      .content {
        h2 {
          @apply opacity-100;
        }
        .text {
          @apply opacity-100;
        }
        .button-link {
          @apply opacity-100;
        }
      }
    }
  }

  .background-image {
    @apply relative w-full;

    img {
      @apply min-w-full max-w-none object-cover;
      max-height: 50vh;
      z-index: 1;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    .background-gradient {
      @apply absolute top-0 block w-full h-full opacity-0;
      transition: all 0.7s ease-in 0.5s;
      background-image: linear-gradient(
        90deg,
        rgba(32, 44, 57, 0.9),
        rgba(32, 44, 57, 0.7) 40%,
        transparent
      );
    }
  }

  .container {
    @apply absolute top-0 h-full w-full flex flex-col items-start justify-center;
    .content {
      @apply w-full text-white text-center pl-8 pr-8;
      z-index: 2;

      h2 {
        @apply uppercase mb-5 opacity-0;
        font-family: 'Inter Regular', sans-serif;
        font-size: 2em;
        line-height: 1.1em;
        letter-spacing: 2px;
        transition: all 0.7s ease-in 1.2s;
      }

      .text {
        @apply mb-8 opacity-0;
        font-family: 'Inter Regular', sans-serif;
        font-size: 1em;
        transition: all 0.7s ease-in 1.9s;
      }

      .button-link {
        @apply opacity-0;
        transition: all 0.7s ease-in 2.2s;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .background-image {
      img {
        max-height: 50vh;
      }
    }

    .container {
      .content {
        @apply pl-16 pr-16;

        h2 {
          font-size: 2.5em;
        }

        .text {
          font-size: 1em;
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .background-image {
      img {
        min-height: 50vh;
        max-height: none;
      }
    }

    .container {
      .content {
        @apply text-left;
        max-width: 600px;

        h2 {
          font-size: 2.6em;
        }

        .text {
          font-size: 1.1em;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      .content {
        @apply pr-36;

        h2 {
          font-size: 3.1em;
        }
      }
    }
  }
}
</style>
