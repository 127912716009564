export default `ingredientSpecifications {
  ... on ingredientSpecifications_BlockType {
    ingredientImage {
      url
      title
    }
    titleText
    description
    specifications {
      ... on specifications_specification_BlockType {
        id
        titleText
        description
      }
    }
    badges {
      ... on badges_badge_BlockType {
        id
        badgeImage {
          url
          title
        }
      }
    }
  }
}`
