<template>
  <div class="badged-highlight-container">
    <div class="topography-canvas-container">
      <WackyTopographyCanvas
        class="canvas"
        :style="`height: ${canvasHeight};`"
      />
    </div>

    <div class="container" ref="contentContainer">
      <div
        class="content"
        v-observe-visibility="{
          callback: contentVisibilityChanged,
          once: true,
          throttle: 600,
          intersection: {
            threshold: 0.3,
          },
        }"
      >
        <h3>{{ highlightData.titleText }}</h3>
        <div class="text" v-html="highlightData.description"></div>
        <div class="button-container">
          <AnchorLink
            type="primaryButton"
            :anchor-link="highlightData.buttonLink"
          >
            {{ highlightData.buttonText }}
          </AnchorLink>
        </div>
      </div>

      <div
        class="badges"
        v-observe-visibility="{
          callback: badgesVisibilityChanged,
          once: true,
          throttle: 600,
          intersection: {
            threshold: 0.3,
          },
        }"
      >
        <div
          class="badge"
          v-for="badge in highlightData.badges"
          :key="badge.id"
        >
          <img
            :src="badge.badgeImage[0].url"
            :alt="badge.badgeImage[0].title"
          />
          <p>{{ badge.titleText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
import WackyTopographyCanvas from '@/components/UI/WackyTopographyCanvas'
export default {
  name: 'BadgedHighlight',
  components: { WackyTopographyCanvas, AnchorLink },
  props: {
    highlightData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      canvasHeight: 'auto',
    }
  },
  mounted() {
    this.adjustCanvasHeight()
    this.listenToResize()
  },
  methods: {
    listenToResize() {
      window.addEventListener('resize', this.adjustCanvasHeight)
    },
    adjustCanvasHeight() {
      if (window.innerWidth < 900) {
        this.canvasHeight = this.$refs.contentContainer.offsetHeight + 'px'
      } else {
        this.canvasHeight = 'auto'
      }
    },
    contentVisibilityChanged(isVisible, entry) {
      if (!isVisible) return
      entry.target.classList.add('showing')
    },
    badgesVisibilityChanged(isVisible, entry) {
      if (!isVisible) return
      entry.target.classList.add('showing')
    },
  },
}
</script>

<style lang="scss" scoped>
.badged-highlight-container {
  @apply relative flex items-center justify-center;

  .topography-canvas-container {
    @apply relative w-full;

    .canvas {
      @apply min-w-full max-w-none object-cover;
      z-index: 1;
      margin-left: 50%;
      transform: translateX(-50%);
      min-height: auto;
    }
  }

  .container {
    @apply absolute top-0 w-full flex flex-col items-center justify-center text-white py-24;

    .content {
      @apply w-full text-center flex flex-col justify-center pb-12;

      &.showing {
        h3 {
          @apply opacity-100 left-0;
        }

        .text {
          @apply opacity-100 left-0;
        }

        .button-container {
          @apply opacity-100 left-0;
        }
      }

      h3 {
        @apply relative whitespace-pre uppercase mb-5 opacity-0;
        left: -2em;
        font-family: 'Inter Medium', sans-serif;
        font-size: 2em;
        line-height: 1.2em;
        transition: all 0.7s ease-in;
      }

      .text {
        @apply relative mb-10 opacity-0;
        left: -3em;
        font-family: 'Inter Regular', sans-serif;
        font-size: 1.1em;
        transition: all 0.7s ease-in 0.4s;
      }

      .button-container {
        @apply relative opacity-0;
        left: -3em;
        transition: all 0.7s ease-in 0.8s;
      }
    }

    .badges {
      @apply w-full flex flex-wrap justify-around items-center my-auto;

      &.showing {
        .badge {
          @apply opacity-100;
        }
      }

      .badge {
        @apply mx-2 w-full flex flex-col items-center justify-start opacity-0;

        @for $i from 1 through 20 {
          &:nth-child(#{$i}n) {
            transition: all 0.7s ease-in #{0.7 + ($i * 0.2)}s;
          }
        }

        &:not(:last-of-type) {
          @apply pb-12;
        }

        img {
          @apply mb-6;
          max-width: 145px;
        }

        p {
          @apply text-center;
          font-family: 'Inter Regular', sans-serif;
          font-size: 0.9em;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      @apply px-20;

      .badges {
        .badge {
          @apply w-1/3;
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      @apply px-14 flex-row items-stretch h-full py-0;

      .content {
        @apply w-2/5 text-left pb-0;
      }

      .badges {
        @apply w-3/5 flex-nowrap;

        .badge {
          @apply h-full;

          &:not(:last-of-type) {
            @apply pb-0;
          }

          img {
            max-width: 120px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .topography-canvas-container {
      .canvas {
        max-height: 600px;
      }
    }

    .container {
      @apply px-20;

      .badges {
        //@apply my-auto;

        .badge {
          img {
            max-width: 145px;
          }
        }
      }
    }
  }
}
</style>
