export default `multiButtonHero {
  ... on multiButtonHero_BlockType {
    backgroundImage {
      url
      title
    }
    subtitle
    buttons {
      ... on buttons_button_BlockType {
        id
        buttonText
        buttonLink
        newTab
      }
    }
  }
}`
