<template>
  <div class="video-highlight-container">
    <div class="container">
      <div class="description" v-html="highlightData.description"></div>

      <div class="video-container">
        <VimeoVideoPlayer :video-url="highlightData.videoUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import VimeoVideoPlayer from '@/components/UI/VimeoVideoPlayer'
export default {
  name: 'VideoHighlight',
  components: { VimeoVideoPlayer },
  props: {
    highlightData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.video-highlight-container {
  .container {
    .description {
      @apply w-11/12 mx-auto py-24;
      font-family: 'Inter Medium', sans-serif;
      font-size: 1em;
      letter-spacing: 1px;
    }

    .video-container {
      @apply w-11/12 mx-auto pb-20;
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      .description {
        @apply w-5/6;
        font-size: 1.2em;
      }
    }
  }
}
</style>
