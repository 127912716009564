<template>
  <router-link v-if="isRouterLink" :class="typeClass" :to="anchorLink">
    <slot></slot>
  </router-link>
  <a
    v-else
    :class="typeClass"
    :href="anchorLink"
    :target="newTab ? '_blank' : '_self'"
    rel="noreferrer"
  >
    <slot></slot>
  </a>
</template>

<script>
const TYPE_PRIMARY_BUTTON = 'primaryButton'
const TYPE_SECONDARY_BUTTON = 'secondaryButton'

export default {
  name: 'AnchorLink',
  props: {
    anchorLink: {
      type: String,
      required: true,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    isRouterLink() {
      return (
        !this.anchorLink.includes('http://') &&
        !this.anchorLink.includes('https://') &&
        this.newTab === false
      )
    },
    typeClass() {
      if (this.type === TYPE_PRIMARY_BUTTON) {
        return 'primary-button'
      } else if (this.type === TYPE_SECONDARY_BUTTON) {
        return 'secondary-button'
      }

      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.primary-button {
  @apply text-white px-6 py-3 font-semibold uppercase;
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 1px;
  background-color: #193153;
  transition: all 0.3s ease-in;

  &:hover,
  &:focus {
    background-color: rgba(25, 49, 83, 0.8);
  }
}

.secondary-button {
  @apply text-white px-6 py-3 font-semibold uppercase;
  font-family: 'Inter Bold', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 1px;
  background-color: transparent;
  border: 4px solid #ffffff;
  transition: all 0.3s ease-in;

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.5);
    color: #193153;
  }
}
</style>
