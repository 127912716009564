<template>
  <SiteHeader />
  <main>
    <LoadingScreen />
    <router-view />
  </main>
  <SiteFooter />
</template>

<script>
import LoadingScreen from '@/components/UI/LoadingScreen'
import SiteHeader from '@/components/Layout/SiteHeader'
import SiteFooter from '@/components/Layout/SiteFooter'
export default {
  components: { SiteFooter, SiteHeader, LoadingScreen },
}
</script>

<style lang="scss">
@import 'sass/app';

#app {
  @apply text-center antialiased;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
}

main {
  @apply overflow-hidden relative;
  min-height: 75vh;
}
</style>
