export default `highlights {
  ... on highlights_badgedHighlight_BlockType {
    id
    typeHandle
    titleText
    description
    buttonText
    buttonLink
    badges {
      ... on badges_BlockType {
        id
        badgeImage {
          url
          title
        }
        titleText
      }
    }
  }
  ... on highlights_centerImageHighlight_BlockType {
    id
    typeHandle
    backgroundImage {
      url
      title
    }
    titleText
    description
    buttonText
    buttonLink
  }
  ...on highlights_sideImageContent_BlockType {
    id
    typeHandle
    titleText
    description
    sideImage {
      url
      title
    }
  }
  ... on highlights_videoHighlight_BlockType {
    id
    typeHandle
    description
    videoUrl
  }
  ... on highlights_processingSteps_BlockType {
    id
    typeHandle
    titleText
    description
    steps {
      ...on steps_BlockType {
        id
        icon {
          url
          title
        }
        stepText
      }
    }
    concludingText
    buttonText
    buttonLink
  }
}`
