<template>
  <footer>
    <div class="container">
      <nav aria-label="footer navigation">
        <AnchorLink
          v-for="linkItem in linkList"
          class="nav-link"
          :key="linkItem.id"
          :anchor-link="linkItem.anchorLink"
          :new-tab="linkItem.newTab"
        >
          {{ linkItem.titleText }}
        </AnchorLink>
      </nav>
    </div>
  </footer>
</template>

<script>
import api from '@/api'
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'SiteFooter',
  components: { AnchorLink },
  data() {
    return {
      linkList: [],
    }
  },
  beforeMount() {
    this.loadSiteFooter()
  },
  methods: {
    loadSiteFooter() {
      api.globals.loadFooter().then((response) => {
        this.setFooterData(response)
      })
    },
    setFooterData(response) {
      if (!response || !response.globalSet) return

      this.linkList = response.globalSet.linkList || []
    },
  },
}
</script>

<style lang="scss" scoped>
footer {
  @apply bg-cover bg-center bg-no-repeat py-16;
  background-image: url('~@/assets/topograph-background.jpg');

  .container {
    nav {
      @apply flex flex-col justify-center;

      .nav-link {
        @apply font-bold uppercase px-5 py-3 text-white;
        font-family: 'Inter SemiBold', sans-serif;
        font-size: 1em;
        letter-spacing: 1px;
        transition: all 0.3s ease-in;

        &:hover,
        &:focus {
          color: #5e80b2;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      nav {
        @apply flex-row;
      }
    }
  }
}
</style>
