<template>
  <div class="side-image-content-container">
    <div class="container">
      <div class="content">
        <h3>{{ highlightData.titleText }}</h3>
        <div class="description" v-html="highlightData.description"></div>
      </div>

      <div class="image-container">
        <img
          :src="highlightData.sideImage[0].url"
          :alt="highlightData.sideImage[0].title"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideImageContentHighlight',
  props: {
    highlightData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.side-image-content-container {
  .container {
    @apply flex flex-wrap items-stretch my-16 px-8;

    .content,
    .image-container {
      @apply flex flex-col items-center justify-center;
    }

    .content {
      @apply w-full mb-12;
    }

    .image-container {
      @apply w-full;
    }

    .content {
      @apply text-left pr-0;

      h3 {
        @apply block w-full text-left uppercase mb-2 text-center;
        font-family: 'Inter SemiBold', sans-serif;
        font-size: 1.7em;
        letter-spacing: 1px;
      }

      .description {
        font-family: 'Inter Regular', sans-serif;
      }
    }
  }

  @media screen and (min-width: 600px) {
  }

  @media screen and (min-width: 900px) {
    .container {
      .content {
        @apply w-1/2 mb-0 pr-20;
      }

      .image-container {
        @apply w-1/2;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      .content {
        @apply w-2/5;

        h3 {
          @apply text-left;
        }
      }

      .image-container {
        @apply w-3/5;
      }
    }
  }
}
</style>
