<template>
  <div class="video-player-container">
    <vueVimeoPlayer
      ref="player"
      class="vimeo-player"
      :video-id="vimeoEmbedId"
      :options="vimeoOptions"
      @loaded="videoLoaded"
    />
  </div>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'
export default {
  name: 'VimeoVideoPlayer',
  components: { vueVimeoPlayer },
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      vimeoOptions: {
        title: false,
        byline: false,
        responsive: true,
        portrait: false,
      },
    }
  },
  computed: {
    vimeoEmbedId() {
      const splitUrl = this.videoUrl.split('/')
      return splitUrl[splitUrl.length - 1]
    },
  },
  methods: {
    videoLoaded() {
      this.$emit('loaded')
    },
  },
}
</script>

<style lang="scss" scoped>
.video-player-container {
  .vimeo-player {
    box-shadow: 0 0 12px 0px rgba(16, 19, 27, 0.66);
  }
}
</style>
