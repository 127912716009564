<template>
  <div class="ingredients-spotlight-container">
    <div class="container">
      <h3>{{ ingredientsSpotlightData.titleText }}</h3>
      <div class="text" v-html="ingredientsSpotlightData.description"></div>

      <div class="spotlight-items">
        <router-link
          class="spotlight-item"
          v-for="spotlightItem in ingredientsSpotlightData.ingredients"
          :key="spotlightItem.id"
          :to="'/' + spotlightItem.uri"
          v-observe-visibility="{
            callback: spotlightItemVisibilityChanged,
            once: true,
            throttle: 600,
            intersection: {
              threshold: 0.3,
            },
          }"
        >
          <div class="image-container">
            <img
              :src="spotlightItem.ingredientTile[0].tileImage[0].url"
              :alt="spotlightItem.ingredientTile[0].tileImage[0].title"
            />
          </div>

          <p>{{ spotlightItem.ingredientTile[0].titleText }}</p>

          <div class="button-container">
            <AnchorLink
              class="button-link"
              type="primaryButton"
              :anchor-link="'/' + spotlightItem.uri"
            >
              Learn More
            </AnchorLink>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'IngredientsSpotlightContainer',
  components: { AnchorLink },
  props: {
    ingredientsSpotlightData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemsLoaded: [],
    }
  },
  watch: {
    itemsLoaded: {
      handler(val) {
        if (val.length === this.ingredientsSpotlightData.ingredients.length) {
          this.$emit('loaded')
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.preloadImages()
  },
  methods: {
    preloadImages() {
      this.ingredientsSpotlightData.ingredients.forEach((ingredient) => {
        let image = document.createElement('img')

        image.onload = () => {
          this.itemsLoaded.push(true)
        }

        image.src = ingredient.ingredientTile[0].tileImage[0].url
      })
    },
    spotlightItemVisibilityChanged(isVisible, entry) {
      if (!isVisible) return
      entry.target.classList.add('showing')
    },
  },
}
</script>

<style lang="scss" scoped>
.ingredients-spotlight-container {
  @apply pt-20 pb-32;

  .container {
    @apply px-10;

    h3 {
      @apply uppercase;
      font-family: 'Inter SemiBold', sans-serif;
      font-size: 2em;
      letter-spacing: 1px;
    }

    .text {
      @apply mb-12 mt-4;
      font-family: 'Inter Regular', sans-serif;
      font-size: 1em;
      line-height: 1.3em;
      letter-spacing: 1px;
    }

    .spotlight-items {
      @apply flex flex-col items-center justify-between;

      .spotlight-item {
        @apply w-4/5 flex flex-col justify-between mb-16 opacity-0;

        @for $i from 1 through 20 {
          &:nth-child(#{$i}n) {
            transition: all 0.7s ease-in #{$i * 0.2}s;
          }
        }

        &.showing {
          @apply opacity-100;
        }

        &:hover {
          .image-container {
            img {
              transform: scale(1.1);
            }
          }
        }

        &:not(:first-of-type) {
          @apply ml-0;
        }

        &:not(:last-of-type) {
          @apply mr-0;
        }

        .image-container {
          @apply overflow-hidden;
          img {
            @apply w-full;
            transition: all 0.6s ease-in;
          }
        }

        p {
          @apply uppercase whitespace-pre mb-6;
          font-family: 'Inter SemiBold', sans-serif;
          font-size: 1em;
          letter-spacing: 1px;
          color: #193153;
        }

        .button-container {
          .button-link {
            @apply inline-block;
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      h3 {
        font-size: 2em;
      }

      .text {
        font-size: 1em;
      }

      .spotlight-items {
        .spotlight-item {
          @apply w-3/5;
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    @apply pt-20 pb-32;

    .container {
      @apply px-20;

      h3 {
        font-size: 2.4em;
      }

      .text {
        font-size: 1.15em;
      }

      .spotlight-items {
        @apply flex-row items-stretch;

        .spotlight-item {
          @apply w-1/3;

          &:not(:first-of-type) {
            @apply ml-4;
          }

          &:not(:last-of-type) {
            @apply mr-4;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      .spotlight-items {
        .spotlight-item {
          &:not(:first-of-type) {
            @apply ml-10;
          }

          &:not(:last-of-type) {
            @apply mr-10;
          }
        }
      }
    }
  }
}
</style>
