<template>
  <header>
    <div class="container">
      <router-link class="home-link" to="/">
        <img
          v-if="!siteLogo"
          src="@/assets/logo.png"
          alt="Peak Season Botanicals Logo"
        />
        <img v-else :src="siteLogo.url" :alt="siteLogo.title" />
      </router-link>

      <nav :class="{ loaded: loaded }" aria-label="main navigation">
        <AnchorLink
          v-for="linkItem in linkList"
          class="nav-link"
          :key="linkItem.id"
          :anchor-link="linkItem.anchorLink"
          :new-tab="linkItem.newTab"
        >
          {{ linkItem.titleText }}
        </AnchorLink>
        <AnchorLink
          v-if="primaryButton"
          class="button-link"
          type="primaryButton"
          :anchor-link="primaryButton.buttonLink"
          :new-tab="primaryButton.newTab"
        >
          {{ primaryButton.buttonText }}
        </AnchorLink>
      </nav>

      <MobileHamburger
        class="hamburger-button"
        @opened="openMobileNav = true"
        @closed="openMobileNav = false"
      />
    </div>

    <MobileNavigation
      :header-data="{ linkList: linkList, primaryButton: primaryButton }"
      :open="openMobileNav"
    />
  </header>
</template>

<script>
import api from '@/api'
import helpers from '@/api/helpers/helpers'
import AnchorLink from '@/components/UI/AnchorLink'
import MobileHamburger from '@/components/Layout/MobileHamburger'
import MobileNavigation from '@/components/Layout/MobileNavigation'
export default {
  name: 'SiteHeader',
  components: { MobileNavigation, MobileHamburger, AnchorLink },
  data() {
    return {
      siteLogo: null,
      linkList: [],
      primaryButton: null,
      openMobileNav: false,
      loaded: false,
    }
  },
  watch: {
    $route() {
      if (!this.openMobileNav) return
      this.openMobileNav = false
    },
  },
  beforeMount() {
    this.loadSiteHeader()
  },
  methods: {
    loadSiteHeader() {
      this.setHeaderData(helpers.readStoredResponse('globals.header'))

      api.globals.loadHeader().then((response) => {
        this.setHeaderData(response)
      })
    },
    setHeaderData(response) {
      if (!response || !response.globalSet) return

      this.siteLogo = response.globalSet.image[0] || null
      this.linkList = response.globalSet.linkList || []
      this.primaryButton = response.globalSet.primaryButton[0] || null

      setTimeout(() => {
        this.loaded = true
      }, 300)
    },
  },
}
</script>

<style lang="scss" scoped>
header {
  box-shadow: 0 0 15px #bbbbbb;

  .container {
    @apply flex justify-between items-center pt-5 pb-4 px-2;

    .home-link {
      @apply w-1/2;
    }

    nav {
      @apply hidden opacity-0;
      transition: all 0.5s ease-in;

      &.loaded {
        @apply opacity-100;
      }

      .nav-link {
        @apply font-bold uppercase px-3 py-3 text-black;
        font-family: 'Inter SemiBold', sans-serif;
        font-size: 1em;
        letter-spacing: 1px;
        transition: all 0.3s ease-in;

        &:hover,
        &:focus,
        &.router-link-active {
          background-color: rgba(25, 49, 83, 0.2);
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      .home-link {
        @apply w-2/5;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      .home-link {
        @apply w-1/4;
      }

      nav {
        @apply inline-block;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      nav {
        .nav-link {
          @apply px-5;
          font-size: 1em;
        }
      }
    }
  }
}
</style>
