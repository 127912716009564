<template>
  <button
    :class="[{ open: isOpen }, 'mobile-hamburger']"
    :aria-label="ariaLabel"
    @click="toggleNavigation"
  >
    <span class="bar" />
    <span class="bar" />
    <span class="bar" />
  </button>
</template>

<script>
export default {
  name: 'MobileHamburger',
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ariaLabel() {
      return (this.isOpen ? 'close' : 'open') + ' mobile navigation'
    },
  },
  watch: {
    $route() {
      if (!this.isOpen) return
      this.isOpen = false
    },
  },
  methods: {
    toggleNavigation() {
      this.isOpen = !this.isOpen

      this.$emit(this.isOpen ? 'opened' : 'closed')
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-hamburger {
  @apply flex flex-col justify-around;
  width: 40px;
  height: 40px;

  span.bar {
    @apply inline-block w-full bg-white relative bottom-0 top-0;
    height: 1px;
    border-bottom: 2px solid #193153;
    transition: all 0.3s linear;
  }

  &.open {
    span.bar {
      &:first-of-type {
        transform: rotate(135deg);
        top: 13px;
      }

      &:nth-of-type(2) {
        @apply opacity-0;
      }

      &:last-of-type {
        transform: rotate(-135deg);
        top: -14px;
      }
    }
  }

  @media screen and (min-width: 900px) {
    @apply hidden;
  }
}
</style>
