import helpers from './helpers/helpers'

export default {
  loadHeader() {
    const requestData = {
      query: `{
        globalSet(handle: "siteHeader") {
          ... on siteHeader_GlobalSet {
            image {
              url
              title
            }
            linkList {
              ... on linkList_link_BlockType {
                id
                titleText
                anchorLink
                newTab
              }
            }
            primaryButton {
              ... on primaryButton_BlockType {
                buttonText
                buttonLink
                newTab
              }
            }
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'globals.header')
  },

  loadFooter() {
    const requestData = {
      query: `{
        globalSet(handle: "siteFooter") {
          ... on siteFooter_GlobalSet {
            linkList {
              ... on linkList_link_BlockType {
                id
                titleText
                anchorLink
                newTab
              }
            }
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'globals.footer')
  },
}
