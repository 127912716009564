<template>
  <div class="highlights-container">
    <template v-for="highlight in highlightsData" :key="highlight.id">
      <BadgedHighlight
        v-if="highlight.typeHandle === 'badgedHighlight'"
        :highlight-data="highlight"
      />
      <CenterImageHighlight
        v-if="highlight.typeHandle === 'centerImageHighlight'"
        :highlight-data="highlight"
      />
      <SideImageContentHighlight
        v-if="highlight.typeHandle === 'sideImageContent'"
        :highlight-data="highlight"
      />
      <VideoHighlight
        v-if="highlight.typeHandle === 'videoHighlight'"
        :highlight-data="highlight"
      />
      <ProcessingStepsHighlight
        v-if="highlight.typeHandle === 'processingSteps'"
        :highlight-data="highlight"
      />
    </template>
  </div>
</template>

<script>
import BadgedHighlight from '@/components/Highlights/BadgedHighlight'
import CenterImageHighlight from '@/components/Highlights/CenterImageHighlight'
import SideImageContentHighlight from '@/components/Highlights/SideImageContentHighlight'
import VideoHighlight from '@/components/Highlights/VideoHighlight'
import ProcessingStepsHighlight from '@/components/Highlights/ProcessingStepsHighlight'
export default {
  name: 'HighlightsContainer',
  components: {
    ProcessingStepsHighlight,
    VideoHighlight,
    SideImageContentHighlight,
    CenterImageHighlight,
    BadgedHighlight,
  },
  props: {
    highlightsData: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped></style>
