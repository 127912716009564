<template>
  <canvas width="1000" height="500"></canvas>
</template>

<script>
export default {
  name: 'WackyTopographyCanvas',
  data() {
    return {
      image: new Image(),
    }
  },
  mounted() {
    this.preloadTopographyImage()
  },
  methods: {
    preloadTopographyImage() {
      this.image.onload = this.waves
      this.image.src = require('@/assets/topograph-background.jpg')
    },
    waves() {
      let canvas = document.querySelector('canvas'),
        ctx = canvas.getContext('2d')

      let w = canvas.width,
        h = canvas.height

      ctx.drawImage(this.image, 0, 0)

      let calculations = {
        o1: new this.Osc(0.05),
        o2: new this.Osc(0.03),
        o3: new this.Osc(0.06), // osc. for vert
        o4: new this.Osc(0.08),
        o5: new this.Osc(0.04),
        o6: new this.Osc(0.067), // osc. for hori
        // source grid lines
        x0: 0.6,
        x1: w * 0.7,
        x2: w * 0.8,
        x3: w * 0.9,
        x4: w,
        y0: 0.6,
        y1: h * 0.7,
        y2: h * 0.8,
        y3: h * 0.9,
        y4: h,
      }

      // cache source widths/heights
      calculations.sw0 = calculations.x1
      calculations.sw1 = calculations.x2 - calculations.x1
      calculations.sw2 = calculations.x3 - calculations.x2
      calculations.sw3 = calculations.x4 - calculations.x3
      calculations.sh0 = calculations.y1
      calculations.sh1 = calculations.y2 - calculations.y1
      calculations.sh2 = calculations.y3 - calculations.y2
      calculations.sh3 = calculations.y4 - calculations.y3

      let vcanvas = document.createElement('canvas'), // off-screen canvas for 2. pass
        vctx = vcanvas.getContext('2d')

      vcanvas.width = w
      vcanvas.height = h

      this.loop(canvas, w, h, ctx, vcanvas, vctx, calculations)
    },
    loop(canvas, w, h, ctx, vcanvas, vctx, calculations) {
      ctx.clearRect(0, 0, w, h)

      for (let y = 0; y < h; y++) {
        // segment positions
        var lx1 = calculations.x1 + calculations.o1.current(y * 0.2) * 2.5,
          lx2 = calculations.x2 + calculations.o2.current(y * 0.2) * 2,
          lx3 = calculations.x3 + calculations.o3.current(y * 0.2) * 1.5,
          // segment widths
          w0 = lx1,
          w1 = lx2 - lx1,
          w2 = lx3 - lx2,
          w3 = calculations.x4 - lx3

        // draw image lines
        ctx.drawImage(
          this.image,
          calculations.x0,
          y,
          calculations.sw0,
          1,
          0,
          y,
          w0,
          1
        )
        ctx.drawImage(
          this.image,
          calculations.x1,
          y,
          calculations.sw1,
          1,
          lx1 - 0.5,
          y,
          w1 + 0.5,
          1
        )
        ctx.drawImage(
          this.image,
          calculations.x2,
          y,
          calculations.sw2,
          1,
          lx2 - 0.5,
          y,
          w2 + 0.5,
          1
        )
        ctx.drawImage(
          this.image,
          calculations.x3,
          y,
          calculations.sw3,
          1,
          lx3 - 0.5,
          y,
          w3 + 0.5,
          1
        )
      }

      // pass 1 done, copy to off-screen canvas:
      vctx.clearRect(0, 0, w, h) // clear off-screen canvas (only if alpha)
      vctx.drawImage(canvas, 0, 0)
      ctx.clearRect(0, 0, w, h) // clear main (onlyif alpha)

      for (let x = 0; x < w; x++) {
        let ly1 = calculations.y1 + calculations.o4.current(x * 0.1) * 2.5,
          ly2 = calculations.y2 + calculations.o5.current(x * 0.1) * 2,
          ly3 = calculations.y3 + calculations.o6.current(x * 0.1) * 1.5

        ctx.drawImage(
          vcanvas,
          x,
          calculations.y0,
          1,
          calculations.sh0,
          x,
          0,
          1,
          ly1
        )
        ctx.drawImage(
          vcanvas,
          x,
          calculations.y1,
          1,
          calculations.sh1,
          x,
          ly1 - 0.5,
          1,
          ly2 - ly1 + 0.5
        )
        ctx.drawImage(
          vcanvas,
          x,
          calculations.y2,
          1,
          calculations.sh2,
          x,
          ly2 - 0.5,
          1,
          ly3 - ly2 + 0.5
        )
        ctx.drawImage(
          vcanvas,
          x,
          calculations.y3,
          1,
          calculations.sh3,
          x,
          ly3 - 0.5,
          1,
          calculations.y4 - ly3 + 0.5
        )
      }

      requestAnimationFrame(() => {
        this.loop(canvas, w, h, ctx, vcanvas, vctx, calculations)
      })
    },
    Osc(speed) {
      let frame = 0

      this.current = function (x) {
        frame += 0.0008 * speed
        return Math.sin(frame + x * speed * 10)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
canvas {
  @apply bg-cover;
  background: url('~@/assets/topograph-background.jpg'), center;
}
</style>
