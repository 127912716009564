<template>
  <div class="steps-highlight-container">
    <div class="container">
      <div
        class="content-container"
        v-observe-visibility="{
          callback: contentVisibilityChanged,
          once: true,
          throttle: 600,
          intersection: {
            threshold: 0.3,
          },
        }"
      >
        <div class="content">
          <h4>{{ highlightData.titleText }}</h4>
          <div class="description" v-html="highlightData.description"></div>
        </div>
      </div>

      <div
        class="steps"
        v-observe-visibility="{
          callback: stepsVisibilityChanged,
          once: true,
          throttle: 600,
          intersection: {
            threshold: 0.25,
          },
        }"
      >
        <div class="step" v-for="step in highlightData.steps" :key="step.id">
          <div class="icon">
            <img :src="step.icon[0].url" :alt="step.icon[0].title" />
          </div>
          <div class="step-text">{{ step.stepText }}</div>
        </div>
      </div>

      <div class="conclusion">
        {{ highlightData.concludingText }}

        <div class="button-container">
          <AnchorLink
            class="button-link"
            :anchor-link="highlightData.buttonLink"
            type="primaryButton"
          >
            {{ highlightData.buttonText }}
          </AnchorLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'ProcessingStepsHighlight',
  components: { AnchorLink },
  props: {
    highlightData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    contentVisibilityChanged(isVisible, entry) {
      if (!isVisible) return
      entry.target.classList.add('showing')
    },
    stepsVisibilityChanged(isVisible, entry) {
      if (!isVisible) return
      entry.target.classList.add('showing')
    },
  },
}
</script>

<style lang="scss" scoped>
.steps-highlight-container {
  @apply py-20;
  background-color: #7d8897;

  .container {
    @apply flex flex-wrap w-11/12 mx-auto;

    .content-container {
      @apply w-full;

      &.showing {
        .content {
          @apply opacity-100 left-0;

          h4 {
            @apply opacity-100;
          }

          .description {
            @apply opacity-100;
          }
        }
      }

      .content {
        @apply relative p-12 mb-16 opacity-0;
        left: -100%;
        background-color: #ffffff;
        color: #7d8897;
        transition: all 0.7s linear;

        h4 {
          @apply uppercase text-left mb-4 opacity-0;
          font-family: 'Inter SemiBold', sans-serif;
          font-size: 2em;
          letter-spacing: 1px;
          line-height: 1.1em;
          transition: all 0.7s ease-in 0.8s;
        }

        .description {
          @apply text-left opacity-0;
          font-family: 'Inter Regular', sans-serif;
          transition: all 0.7s ease-in 1.2s;
        }
      }
    }

    .steps {
      @apply w-full text-white;

      &.showing {
        .step {
          @apply opacity-100;
        }
      }

      .step {
        @apply flex items-center text-left pb-6 opacity-0;

        @for $i from 1 through 20 {
          &:nth-child(#{$i}n) {
            transition: all 0.7s ease-in #{$i * 0.2}s;
          }
        }

        .icon {
          @apply px-4 flex justify-center items-center;

          img {
            @apply inline-block max-w-none;
          }
        }

        .step-text {
          font-family: 'Inter SemiBold', sans-serif;
          font-size: 1em;
        }
      }
    }

    .conclusion {
      @apply w-full text-white py-10;
      font-family: 'Inter SemiBold', sans-serif;
      font-size: 1.3em;
      letter-spacing: 1px;

      .button-container {
        @apply block ml-0 mt-4;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      .content-container {
      }

      .steps {
        .step {
          .step-text {
            font-size: 1.1em;
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      .content-container {
        @apply w-5/12;
      }

      .steps {
        @apply w-7/12;

        .step {
          @for $i from 1 through 20 {
            &:nth-child(#{$i}n) {
              transition: all 0.7s ease-in #{1.5 + ($i * 0.2)}s;
            }
          }

          .icon {
            @apply px-8;
          }
        }
      }

      .conclusion {
        .button-container {
          @apply inline-block ml-3 mt-0;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      .content-container {
        @apply w-1/3;
      }

      .steps {
        @apply w-2/3;

        .step {
          .step-text {
            font-size: 1.3em;
          }
        }
      }
    }
  }
}
</style>
