require('./bootstrapped')

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faSpinner, faCheckCircle)

// Allow craftCMS previews.
let m = document.location.href.match(/\btoken=([^&]+)/)
const previewToken = m ? m[1] : ''
const requestPath = previewToken === '' ? '/api' : '/api?token=' + previewToken

const app = createApp(App)

axios.create({
  baseURL: requestPath,
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_AUTHORIZATION_TOKEN}`,
  },
})

app.config.globalProperties.$axios = axios

import { ObserveVisibility } from 'vue-observe-visibility'

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .component('FontAwesomeIcon', FontAwesomeIcon)
  .directive('observe-visibility', {
    beforeMount: (el, binding, vnode) => {
      vnode.context = binding.instance
      ObserveVisibility.bind(el, binding, vnode)
    },
    update: ObserveVisibility.update,
    unmounted: ObserveVisibility.unbind,
  })
  .mount('#app')
