export default `primaryHero {
  ...on primaryHero_hero_BlockType {
    backgroundImage {
      url
    }
    contentAlignment
    titleText
    description
    buttonText
    buttonLink
  }
}`
