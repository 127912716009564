<template>
  <div id="homeView">
    <PrimaryHero
      v-if="primaryHeroData"
      :primary-hero-data="primaryHeroData"
      @loaded="heroLoaded = true"
    />
    <IngredientsSpotlightContainer
      v-if="ingredientsSpotlightData && heroLoaded"
      :ingredients-spotlight-data="ingredientsSpotlightData"
      @loaded="ingredientsSpotlightLoaded = true"
    />
    <HighlightsContainer :highlights-data="highlightsData" />
  </div>
</template>

<script>
// @ is an alias to /src
import api from '@/api'
import { mapMutations, mapState } from 'vuex'
import PrimaryHero from '@/components/Hero/PrimaryHero'
import IngredientsSpotlightContainer from '@/components/Ingredients/IngredientsSpotlightContainer'
import HighlightsContainer from '@/components/Highlights/HighlightsContainer'
import helpers from '@/api/helpers/helpers'

export default {
  name: 'Home',
  components: {
    HighlightsContainer,
    IngredientsSpotlightContainer,
    PrimaryHero,
  },
  data() {
    return {
      primaryHeroData: null,
      ingredientsSpotlightData: null,
      highlightsData: [],
      heroLoaded: false,
      ingredientsSpotlightLoaded: false,
    }
  },
  computed: {
    ...mapState({
      pageLoading: (state) => state.pageLoading,
    }),
  },
  watch: {
    heroLoaded: {
      handler(newVal) {
        if (!newVal) return
        this.updatePageLoading()
      },
    },
    ingredientsSpotlightLoaded: {
      handler(newVal) {
        if (!newVal) return
        this.updatePageLoading()
      },
    },
  },
  beforeMount() {
    this.loadHomeView()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadHomeView() {
      this.setHomeData(helpers.readStoredResponse('view.home'))

      api.views.loadHome().then((response) => {
        this.setHomeData(response)
      })
    },
    setHomeData(response) {
      if (!response || !response.entry) return

      this.primaryHeroData = response.entry.primaryHero[0] || null
      this.ingredientsSpotlightData =
        response.entry.ingredientsSpotlight[0] || null
      this.highlightsData = response.entry.highlights || []
    },
    updatePageLoading() {
      if (this.heroLoaded && this.ingredientsSpotlightLoaded) {
        setTimeout(() => {
          this.setPageLoadingFalse()
        }, 300)
      }
    },
  },
}
</script>
